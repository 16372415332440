@media screen and (max-width: 949px) {

.intro {
    text-align: left;
    font-size: 20px;
    font-family: NotoSansBold;
    color: #222222;
   margin: 0;
}

.possCoinTitle {
    width: 100%;
    text-align: left;
    display: inline-block;
    color: #999999;
    font-size: 20px;
    font-family: NotoSansMedium;
    letter-spacing: -1px;
}

.possCoinText {
    text-align: left;
}

.underArrow {
    margin-left: 10px;
}

.possCoinContent {
    width: 100%;
    padding: 30px 0px;
    margin-top: 23px;
    text-align: center;
    background-color: #f9f9f9;
    border-top: #e2e2e2 1px solid;
}

.miniLogo {
    width: 60px;
    height: 60px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -15px;
}

.coinUnit {
    display: inline-block;
    padding-left: 5px;
    color: #888888;
    font-size: 20px;
    font-family: NotoSansBold;
}

.possCoin {
    margin-left: 20px;
    display: inline-block;
    font-size: 28px;
    font-family: NotoSansBold;
}

.possCoinListTitle {
    width: 100%;
    text-align: left;
    display: inline-block;
    margin-top: 20px;
    color: #999999;
    font-size: 20px;
    font-family: NotoSansMedium;
    letter-spacing: -1px;
}

.searchBtn {
    float: right;
    width: 15px;
    margin-top: 7px;
}

.spanLine {
    width: 100%;
    background-image: url('../img/myWallet/spanLine.png');
    background-repeat: no-repeat;
    background-size: 100%;
    margin-top: 12px;
}

.blockchainContent {
    background-color: #f9f9f9;
    border-top: #e2e2e2 1px solid;
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.blockchainImage {
    width: 15%;
    height: 30px;
    align-self: center;
    & > img{
        width: auto;
        height: 30px;
        }
}

.blockchainTitle {
    width: 35%;
    font-size: 18px;
    font-family: NotoSansRegular;
}

.blockchainContentName {
    font-size: 16px;
    color: #222222;
    font-family: NotoSansRegular;
}

.blockchainContentSymbol {
    color: #888888;
    font-size: 14px;
    font-family: NotoSansRegular;
}

.costTable {
    width: 50%;
    margin-right: 14px;
}

.blockchainCost {
    height: auto;
    text-align: right;
}

.costNum {
    color: #693cff;
    font-family: NotoSansMedium;
    font-size: 18px;
}

.costSymbol {
    color: #888888;
    font-family: NotoSansRegular;
    margin-left: 5px;
    font-size: 14px;
}

.costKo {
    color: #888888;
    font-family: NotoSansRegular;
    font-size: 14px;
}

.costKoSymbol {
    color: #888888;
    font-family: NotoSansRegular;
    margin-left: 5px;
    font-size: 14px;
}
.minus-box img{
    width: 30px;
}


.coinUnitList {
    width: 15px;
    height: 80px;
    background-image: url('../img/myWallet/coinUnit.png');
    background-repeat: no-repeat;
    background-size: 15px 15px;
    background-position-y: center;
    float: right;
}

.plusBtn {
    width: 27px;
    height: 27px;
    background-image: url('../img/myWallet/plusBtn.png');
    background-repeat: no-repeat;
    background-size: 100%;
    float: right;
    margin-left: 80px;
    margin-top: 9px;
}

.plusSelectedBtn {
    width: 27px;
    height: 27px;
    background-image: url('../img/myWallet/plusSelectedBtn.png');
    background-repeat: no-repeat;
    background-size: 100%;
    float: right;
    margin-left: 80px;
    margin-top: 9px;
}

.contentDetail {
    width: 100%;
    height: 200px;
    display: table-row;
    background-image: url('../img/myWallet/detailBox.png');
    background-repeat: no-repeat;
    background-size: 100%;
}

.tranHistory {
    float: left;
    width: 70%;
    height: 100%;
    padding: 30px 10px 20px 90px;
}


.submitBtn {
    float: right;
    width: 30%;
    height: 100%;
}

.tranHisText {
    float: left;
    color: #888888;
    font-family: NotoSansRegular;
}

.latestBtn {
    float: right;
    color: #888888;
    font-family: NotoSansRegular;
    width: 70px;
    height: 30px;
    background-image: url('../img/myWallet/latestBtn.png');
    background-repeat: no-repeat;
    background-size: 100%;
    margin-right: 15px;
}

.dawBtn {
    width: 100px;
    height: 30px;
    float: right;
    color: #888888;
    font-family: NotoSansRegular;
    background-image: url('../img/myWallet/dawBtn.png');
    background-repeat: no-repeat;
    background-size: 100%;
    margin-right: 15px;
}

.underArrowDetail {
    float: right;
    width: 14px;
    margin-top: 8px;
}

.spanLineDetail {
    width: 100%;
    height: 4px;
    display: inline-block;
    background-image: url('../img/myWallet/spanLineDetail.png');
    background-repeat: no-repeat;
    background-size: 100%;
}

.transDate {
    float: left;
}

.transKoSymbol {
    float: left;
}

.transCost {
    float: right;
}

.transCostSymbol {
    float: right;
}

.dawKind {
    float: right;
}

.receiveBtn {

}

.sendBtn {
    
}
}