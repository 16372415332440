
@media screen and (min-width: 950px) {
    //변수
    $mainColor : #35b7ae;
    $fontFamliy : NotoSansRegular;
    $pinkColor : #e62167;
    .wrapperMain {
        // background-image: url('../img/join_bg.png');
        // background-image: url('../img/main/menuBarBg.png');
        // background-repeat: repeat-x;
        // background-position-x: center;
        // background-position-y: top;
        width: 100vw;
        background-color: #35b7ae ;
        height: 400px;
        position: relative;
        text-align: center;
    }

    .mainContent {
        display: inline-block;
        // width: 900px;
        margin-top: 50px;
    }

    .flag {
        background-image: url('../img/main/flag.png');
        background-repeat: no-repeat;
        background-position-x: left;
        background-position-y: top;
        width: 65px;
        height: 80px;
        background-size: 45px 30px;
        float: left;
        position: relative;
    }

    .country {
        background-image: url('../img/main/ko.png');
        background-repeat: no-repeat;
        background-position-x: left;
        background-position-y: top;
        width: 30px;
        height: 50px;
        background-size: 30px 18px;
        float: left;
        position: relative;
        margin-top: 6px;
    }

    .mainLogo {
        background-image: url('../img/main/logo2-w.png');
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: bottom;
        height: 180px;
        width: 400px;
        display: inline-block;
        background-size: contain;
        margin: 0 auto;
    }

    .profile {
        background-image: url('../img/main/profile.png');
        background-repeat: no-repeat;
        background-position-x: right;
        background-position-y: top;
        width: 40px;
        height: 100px;
        background-size: 37px 37px;
        float: right;
        position: relative;
    }

    .BT-HD-right-container{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
    .blank_box{
        width: 40px;       
    }    
    .logout {
        width: 40px;
        cursor: pointer;
    }
    .login {
        width: 40px;
        cursor: pointer;
    }
    .tab {
        margin-top: 60px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;
        padding: 0;
    }

    .tabText {
        margin-top: 20px;
    }

    .myWallet {
        width: 140px;
        height: 60px;
        float: left;
        list-style: none;
    }

    .selBox {
        background-image: url('../img/main/tabSel.png');
        background-repeat: no-repeat;
        background-position-y: top;
        width: 180px;
        height: 70px;
        background-size: 180px 100px;
        list-style: none;
        cursor: pointer;
        font-family: NotoSansMedium;
        font-size: 24px;
        padding-top: 16px;
    }

    .nonSelBox {
        width: 180px;
        height: 70px;
        list-style: none;
        cursor: pointer;
        font-family: NotoSansMedium;
        font-size: 24px;
        padding-top: 16px;
        color: #fff;
    }

        
    .walletContent {
        background-color: #fff;
        border-radius: 0px 40px 40px 40px;
        box-shadow: 0px 15px 15px 0px #d1d1d1;
        width: 900px;
        padding: 58px;
    }

    .mobile-message {
        color: #fff;
        font-size: 20px;
        font-family: NotoSansBold;
        margin-top: 50px;
    }
    .mobile-button {
        color: #000;
        background-color: #fff;
        font-size: 20px;
        font-family: NotoSansBold;
        margin-top: 20px;
        display: inline-block;
        padding: 10px 30px;
        cursor: pointer;
    }
    .divider {
        width: 100%;
        height: 2px;
        background-color: #d1d1d1;
    }
    .divider-slim {
        width: 100%;
        height: 1px;
        background-color: #d1d1d1;
    }
    .pagination {
        text-align: center;
    }

    .currency-content {
        text-align: left;
        background-color: #fff;
        border-radius: 40px;
        box-shadow: 0px 15px 15px 0px #d1d1d1;
        width: 900px;
        padding: 58px;
    }
    .currency-top-title {
        text-align: left;
        display: inline-block;
        color: #999999;
        font-size: 20px;
        font-family: NotoSansMedium;
        letter-spacing: -1px;
        padding-bottom: 20px;
    }
    .currency-top-more {
        float: right;
        cursor: pointer;
        color: #000;
        font-size: 16px;
        font-family: NotoSansMedium;
        letter-spacing: -1px;
        padding: 5px 20px;
        border: #999999 1px solid;
        border-radius: 5px;
    }
    .currency-top-time {
        display: inline-block;
        letter-spacing: -1px;
        color: #b2b2b2;
        font-size: 16px;
        font-family: NotoSansRegular;
        letter-spacing: -1px;
        margin-left: 50px;
    }
    .currency-top-refresh {
        float: right;
        cursor: pointer;
    }
    .currency-notice-container {
        padding: 0px 20px;
        background-color: #f9f9f9;
        margin-bottom: 30px;
    }
    .currency-notice-item {
        padding: 20px 0px;
    }
    .currency-notice-no {
        display: inline-block;
        width: 100px;
        text-align: center;
        font-size: 16px;
        font-family: NotoSansRegular;
        letter-spacing: -1px;
        color: #999999;
    }
    .currency-notice-title {
        display: inline-block;
        width: 500px;
        font-size: 16px;
        font-family: NotoSansRegular;
        letter-spacing: -1px;
    }
    .currency-notice-date {
        display: inline-block;
        font-size: 16px;
        font-family: NotoSansRegular;
        letter-spacing: -1px;
        color: #999999;
    }

    .currency-coin-container {
        padding: 20px 40px;
        margin: 20px 0px;
        background-color: #f9f9f9;
    }
    .currency-coin-image {
        display: inline-block;
        width: 100px;
        text-align: center;
        vertical-align: text-bottom;
    }
    .currency-coin-image img {
        max-width: 40px;
        max-height: 40px;
    }
    .currency-coin-symbol {
        display: inline-block;
        width: 200px;
    }
    .currency-coin-symbol-name {
        color: #000;
        font-size: 16px;
        font-family: NotoSansRegular;
        letter-spacing: -1px;
    }
    .currency-coin-symbol-mark {
        color: #999999;
        font-size: 14px;
        font-family: NotoSansRegular;
        letter-spacing: -1px;
    }
    .currency-coin-price {
        display: inline-block;
        width: 250px;
        vertical-align: top;
        padding: 10px;
        text-align: right;
    }
    .currency-coin-price-num {
        display: inline-block;
        font-size: 20px;
        font-family: NotoSansMedium;
        color: #693cff;
    }
    .currency-coin-price-unit {
        display: inline-block;
        font-size: 16px;
        font-family: NotoSansRegular;
        color: #999999;
        padding-left: 5px;
    }
    .currency-coin-change {
        display: inline-block;
        vertical-align: top;
        width: 150px;
        text-align: right;
    }
    .currency-coin-change-num {
        display: inline-block;
        font-size: 16px;
        font-family: NotoSansRegular;
        letter-spacing: -1px;
        padding: 10px;
    }
    .currency-coin-change-arrow {
        display: inline-block;
        padding-left: 10px;
        vertical-align: text-bottom;
    }


    .event-content {
        text-align: left;
        background-color: #fff;
        border-radius: 40px 0 40px 40px;
        box-shadow: 0px 15px 15px 0px #d1d1d1;
        width: 900px;
        padding: 58px;
    }
    .event-top-title {
        text-align: left;
        display: inline-block;
        color: #999999;
        font-size: 20px;
        font-family: NotoSansMedium;
        letter-spacing: -1px;
        padding-bottom: 20px;
    }
    .event-item-container {
        padding: 20px;
        cursor: pointer;
        border-bottom: 1px solid #d1d1d1;
    }
    .event-item-title-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;;
    }
    .event-item-title {
        font-size: 20px;
        font-family: NotoSansMedium;
        letter-spacing: -1px;
        width: 85%;
        overflow: hidden;
        white-space:nowrap;
        text-overflow: ellipsis;

    }
    .event-item-link {
        font-size: 16px;
        font-family: NotoSansRegular;
        letter-spacing: -1px;
        padding: 5px 20px;
        background-color: #ff9f40;
        border-radius: 5px;
        color: #fff;
        cursor: pointer;
    }
    .event-img-container{
        height: 120px; 
    }
    .myevent-item-container {
        padding: 20px;
        border: 1px #d1d1d1 solid;
        border-radius: 10px;
        margin: 20px 0px;
    } 
    .myevent-item-title {
        font-size: 18px;
        font-family: NotoSansRegular;
        letter-spacing: -1px;
        color: #000;
        width: 85%;
        overflow: hidden;
        white-space:nowrap;
        text-overflow: ellipsis;
    } 
    .myevent-item-status-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .myevent-item-status-wrapper2 {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-top: 4px;;
    }
    .myevent-item-status {
        font-size: 16px;
        font-family: NotoSansRegular;
        letter-spacing: -1px;
        color: #000;
        background-color: #e1e1e1;
        padding: 5px 20px;
        border-radius: 10px;
    } 
    .myevent-item-amount {
        font-size: 20px;
        font-family: NotoSansMedium;
        letter-spacing: -1px;
        color: orange;
        display: inline-block;
        width: 40%;
    } 
    .myevent-item-date {
        font-size: 16px;
        font-family: NotoSansRegular;
        letter-spacing: -1px;
        color: #999999;

    } 

    .notice-item-container {
        background-color: #f9f9f9;
        padding: 10px 20px;
        margin: 10px 0px; 
    }
    .notice-item-title {
        font-size: 20px;
        font-family: NotoSansMedium;
        letter-spacing: -1px;
        color: #000;
    }
    .notice-item-sub {
        
    }
    .notice-item-category {
        font-size: 14px;
        font-family: NotoSansRegular;
        letter-spacing: -1px;
        color: #000;
        border: 1px #999999 solid;
        border-radius: 10px;
        padding: 5px 20px;
        display: inline-block;
    }
    .notice-item-date {
        font-size: 16px;
        font-family: NotoSansRegular;
        letter-spacing: -1px;
        color: #999999;
        float: right;
    }
    .notice-item-content {
        padding: 30px;
    }

    .trade-container {
        background-color: #f9f9f9;
        border-top: 1px solid #e2e2e2;
        padding: 20px 40px 30px 80px;
        text-align: left;
    }
    .trade-title {
        display: inline-block;
        font-size: 20px;
        font-family: NotoSansRegular;
        letter-spacing: -1px;
        margin: 20px 0px;
    }
    .trade-button-container {
        float: right;
    }
    .trade-button {
        margin: 20px 0px 20px 10px;
    }

    .trade-item-container {
        padding: 10px 20px;
        background-color: #fff;
        margin: 10px 0px;
        font-size: 18px;
        font-family: NotoSansRegular;
        letter-spacing: -1px;
    }
    .trade-item-title {
        padding: 10px 0px;
    }
    .trade-state {
        font-size: 16px;
        display: inline-block;
        border: 1px solid;
        padding: 5px 10px;
        border-radius: 5px;
    }
    .trade-amount {
        display: inline-block;
        font-size: 24px;
        font-family: NotoSansBold;
        margin-left: 20px;
    }
    .trade-amount-unit {
        display: inline-block;
        font-size: 16px;
        font-family: NotoSansBold;
        margin-left: 5px;
    }
    .trade-date {
        display: inline-block;
        float: right;
        color: #999999;
    }
    .receive-qr-title {
        font-size: 24px;
        font-family: NotoSansBold;
        margin-bottom: 20px;
    }
    .receive-qr-address {
        text-align: center;
        font-size: 18px;
        font-family: $fontFamliy;
        letter-spacing: -1px;
        color: $pinkColor;
        margin: 12px 0;
    }
    .trade-send-container {
        background-color: #f9f9f9;
        border-top: 1px solid #e2e2e2;
        padding: 20px 40px 30px 80px;
        text-align: left;
        font-size: 16px;
        font-family: NotoSansRegular;
        letter-spacing: -1px;
    }
    .trade-send-title {
        display: inline-block;
        font-size: 20px;
        font-family: NotoSansRegular;
        letter-spacing: -1px;
        margin: 20px 0px;
    }
    .trade-send-title-box{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
    }
    .trade-send-button {
       
    }

    .trade-send-confirm-modal {
        font-size: 18px;
        font-family: NotoSansRegular;
        letter-spacing: -1px;
    }
    .trade-send-confirm-title {
        font-size: 20px;
        font-family: NotoSansRegular;
        letter-spacing: -1px;
        margin: 20px 0px;
    }
    .trade-send-confirm-item {
        display: inline-block;
        line-height: 30px;
        font-size: 16px;
        
    }
    .mytran-header-back-wrapper {
        float: left;
        cursor: pointer;
    }
}