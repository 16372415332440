
@media screen and (max-width: 949px) {
    //변수
    $mainColor : #35b7ae;
    $fontFamliy : NotoSansRegular;
    $pinkColor : #e62167;
    .wrapperMain {
        width: 100vw;
        background-color: #35b7ae ;
        height: 50vh;
        text-align: center;
    }

    .mainContent {
        width: 100%;
        padding: 0 16px;
    }

    .flag {
        background-image: url('../img/main/flag.png');
        background-repeat: no-repeat;
        background-position-x: left;
        background-position-y: top;
        width: 65px;
        height: 80px;
        background-size: 45px 30px;
        float: left;
        position: relative;
    }

    .country {
        background-image: url('../img/main/ko.png');
        background-repeat: no-repeat;
        background-position-x: left;
        background-position-y: top;
        width: 30px;
        height: 50px;
        background-size: 30px 18px;
        float: left;
        position: relative;
        margin-top: 6px;
    }

    .mainLogo {
        background-image: url('../img/main/logo2-w.png');
        background-repeat: no-repeat;
        background-position-y: bottom;
        height: 80px;
        width: 200px;
        background-size: contain;
    }

    .profile {
        background-image: url('../img/main/profile.png');
        background-repeat: no-repeat;
        background-position-x: right;
        background-position-y: top;
        width: 40px;
        height: 100px;
        background-size: 37px 37px;
        float: right;
        position: relative;
    }

    .BT-HD-right-container{
        padding: 12px 0;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    .logout img{
        width: 20px;
        cursor: pointer;
    }
    .login img{
        width: 20px; 
        cursor: pointer;   
    }

    .tab {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;
        padding: 0;
    }

    .tabText {
        margin-top: 20px;
    }

    .myWallet {
        width: 140px;
        height: 60px;
        float: left;
        list-style: none;
    }
    .possCoinTitle{
        display: inline-block;
    }
    .selBox {
        list-style: none;
        cursor: pointer;
        font-family: NotoSansMedium;
        font-size: 16px;
        padding-top: 16px;
        text-align: center;
        width: 25%;
        background-color: #fff;
        border-radius: 10px 10px 0 0;
        padding: 10px 0;
    }

    .nonSelBox {
        list-style: none;
        cursor: pointer;
        font-family: NotoSansMedium;
        font-size: 16px;
        padding-top: 16px;
        color: #fff;
        text-align: center;
        width: 25%;
        padding: 10px 0;
    }

        
    .walletContent {
        min-height: 30vh;
        text-align: left;
        background-color: #fff;
        border-radius: 0 12px 12px 12px;
        box-shadow: 0px 5px 5px 0px #d1d1d1;
        width: 100%;
        padding: 14px;
    }

    .mobile-message {
        color: #fff;
        font-size: 20px;
        font-family: NotoSansBold;
        margin-top: 50px;
    }
    .mobile-button {
        color: #000;
        background-color: #fff;
        font-size: 20px;
        font-family: NotoSansBold;
        margin-top: 20px;
        display: inline-block;
        padding: 10px 30px;
        cursor: pointer;
    }
    .divider {
        width: 100%;
        height: 2px;
        background-color: #d1d1d1;
    }
    .divider-slim {
        width: 100%;
        height: 1px;
        background-color: #d1d1d1;
    }
    .pagination {
        text-align: center;
        margin-top: 10px;
    }

    .currency-content {
        min-height: 30vh;
        text-align: left;
        background-color: #fff;
        border-radius: 12px;
        box-shadow: 0px 5px 5px 0px #d1d1d1;
        width: 100%;
        padding: 14px;
    }
    .currency-top-title {
        text-align: left;
        display: inline-block;
        color: #999999;
        font-size: 20px;
        font-family: NotoSansMedium;
        letter-spacing: -1px;
        padding-bottom: 20px;
    }
    .currency-top-more {
        float: right;
        cursor: pointer;
        color: #000;
        font-size: 16px;
        font-family: NotoSansMedium;
        letter-spacing: -1px;
        padding: 5px 20px;
        border: #999999 1px solid;
        border-radius: 5px;
    }
    .currency-top-time {
        display: inline-block;
        letter-spacing: -1px;
        color: #b2b2b2;
        font-size: 12px;
        font-family: NotoSansRegular;
        letter-spacing: -1px;
        margin-left: 12px;
    }
    .currency-top-refresh {
        float: right;
        cursor: pointer;
        & > img {
            width: 24px;
            margin-top: -4px;
            margin-left: -20px;
        }
    }
    .currency-notice-container {
        padding: 10px;
        background-color: #f9f9f9;
        margin-bottom: 30px;        
    }
    .currency-notice-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .currency-notice-no {
        width: 30px;
        text-align: center;
        font-size: 16px;
        font-family: NotoSansRegular;
        letter-spacing: -1px;
        color: #999999;
    }
    .currency-notice-title {
        width: 70%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 16px;
        font-family: NotoSansRegular;
        letter-spacing: -1px;
    }
    .currency-notice-date {  
        font-size: 12px;
        font-family: NotoSansRegular;
        letter-spacing: -1px;
        color: #999999;
    }

    .currency-coin-container {
        padding: 10px 0;
        margin: 20px 0px;
        background-color: #f9f9f9;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }
    .currency-coin-image {
        display: inline-block;
        width: 12%;
        text-align: center;
        vertical-align: text-bottom;
    }
    .currency-coin-image img {
        max-width: 24px;
        max-height: 24px;
    }
    .currency-coin-symbol {
        width: 23%;
    }
    .currency-coin-symbol-name {
        color: #000;
        font-size: 16px;
        font-family: NotoSansRegular;
        letter-spacing: -1px;
    }
    .currency-coin-symbol-mark {
        color: #999999;
        font-size: 14px;
        font-family: NotoSansRegular;
        letter-spacing: -1px;
        margin-top: -4px;
    }
    .currency-coin-price {
        width: 48%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .currency-coin-price-num {
        font-size: 18px;
        font-family: NotoSansMedium;
        color: #693cff;
    }
    .currency-coin-price-unit {
        font-size: 16px;
        font-family: NotoSansRegular;
        color: #999999;
        margin: 0 4px;
    }
    .currency-coin-change {   
        width: 16%;
        display: flex;
        align-items: center;
        justify-content: flex-end
    }
    .currency-coin-change-num {   
        font-size: 14px;
        font-family: NotoSansRegular;
        letter-spacing: -1px;
    }
    .currency-coin-change-arrow img {     
        width: 12px;
        height: 14px;
        padding-left: 2px;
        vertical-align: text-bottom;
    }


    .event-content {
        min-height: 30vh;
        text-align: left;
        background-color: #fff;
        border-radius: 20px 0 20px 20px;
        box-shadow: 0px 5px 5px 0px #d1d1d1;
        width: 100%;
        padding: 14px;
    }
    .event-top-title {
        text-align: left;
        display: inline-block;
        color: #999999;
        font-size: 20px;
        font-family: NotoSansMedium;
        letter-spacing: -1px;
        padding-bottom: 20px;
    }
    .event-item-container {
        padding: 10px;
        cursor: pointer;
        border-bottom: 1px solid #d1d1d1;
    }
    .event-item-title-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;;
    }
    .event-item-title {
        font-size: 18px;
        font-family: NotoSansMedium;
        letter-spacing: -1px;
        width: 80%;
        overflow: hidden;
        white-space:nowrap;
        text-overflow: ellipsis;
    }
    .event-item-link {
        float: right;
        font-size: 14px;
        font-family: NotoSansRegular;
        letter-spacing: -1px;
        padding: 5px;
        background-color: #ff9f40;
        border-radius: 5px;
        color: #fff;
        cursor: pointer;
    }
    .event-img-container img{
        width: 100%;
    }
    .myevent-item-container {
        padding: 10px;
        border: 1px #d1d1d1 solid;
        border-radius: 10px;
        margin: 20px 0px;
    } 
    .signInBtn {
        font-family: NotoSansRegular;
        font-size: 14px;
        border: 1px solid #b7b7b7;
        border-radius: 10px;
        padding: 2px 12px;
        color: #b7b7b7;
    }
    .myevent-item-title {
        font-size: 16px;
        font-family: NotoSansRegular;
        letter-spacing: -1px;
        color: #000;
        width: 70%;
        overflow: hidden;
        white-space:nowrap;
        text-overflow: ellipsis;
    } 
    .myevent-item-status-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .myevent-item-status-wrapper2 {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-top: 4px;;
    }
    .myevent-item-status {
        font-size: 16px;
        font-family: NotoSansRegular;
        letter-spacing: -1px;
        color: #000;
        background-color: #e1e1e1;
        padding: 5px;
        border-radius: 10px;
    } 
    .myevent-item-amount {
        font-size: 20px;
        font-family: NotoSansMedium;
        letter-spacing: -1px;
        color: orange;
        display: inline-block;
        width: 40%;
    } 
    .myevent-item-date {
        font-size: 16px;
        font-family: NotoSansRegular;
        letter-spacing: -1px;
        color: #999999;
        display: inline-block;
        width: 50%;
        text-align: right;
    } 

    .notice-item-container {
        background-color: #f9f9f9;
        padding: 10px 20px;
        margin: 10px 0px; 
    }
    .notice-item-title {
        font-size: 20px;
        font-family: NotoSansMedium;
        letter-spacing: -1px;
        color: #000;
    }
    .notice-item-sub {
        
    }
    .notice-item-category {
        font-size: 14px;
        font-family: NotoSansRegular;
        letter-spacing: -1px;
        color: #000;
        border: 1px #999999 solid;
        border-radius: 10px;
        padding: 5px 20px;
        display: inline-block;
    }
    .notice-item-date {
        font-size: 16px;
        font-family: NotoSansRegular;
        letter-spacing: -1px;
        color: #999999;
        float: right;
    }
    .notice-item-content {
        padding: 30px;
    }

    .trade-container {
        background-color: #f9f9f9;
        border-top: 1px solid #e2e2e2;
        padding: 10px 20px;
        text-align: left;
    }
    .trade-title-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 6px;
    }
    .trade-title {
        display: inline-block;
        font-size: 16px;
        font-family: NotoSansRegular;
        letter-spacing: -1px;
    }
    .trade-button-container {
        display: flex;
        align-items: center;
    }
    .trade-button {
        width: 60px;
        margin-left: 4px;
        font-size: 14px;
        letter-spacing: -1px;
        padding: 5px;
    }

    .trade-item-container {
        padding: 10px 20px;
        background-color: #fff;
        margin: 10px 0px;
        font-size: 16px;
        font-family: NotoSansRegular;
        letter-spacing: -1px;
    }
    .trade-item-title {
        padding-bottom: 10px;
    }
    .trade-state {
        font-size: 16px;
        display: inline-block;
        border: 1px solid;
        padding: 3px 5px;
        border-radius: 5px;
    }
    .trade-amount {
        display: inline-block;
        font-size: 18px;
        font-family: NotoSansBold;
        margin-left: 20px;
    }
    .trade-amount-unit {
        display: inline-block;
        font-size: 16px;
        font-family: NotoSansBold;
        margin-left: 5px;
    }
    .trade-date {
        font-size: 14px;
        margin-top: 6px;
        color: #999999;
    }
    .receive-qr-title {
        font-size: 16px;
        font-family: NotoSansBold;
        margin-bottom: 10px;
    }
    .receive-qr-address {
        text-align: center;
        font-size: 16px;
        font-family: $fontFamliy;
        letter-spacing: -1px;
        color:$pinkColor;
        margin: 8px 0;
    }
    .qr-img-box{
        & > canvas {
            width: 200px;
        }
    }
    .trade-send-container {
        background-color: #f9f9f9;
        border-top: 1px solid #e2e2e2;
        padding: 20px 40px 30px 80px;
        text-align: left;
        font-size: 16px;
        font-family: NotoSansRegular;
        letter-spacing: -1px;
    }
    .trade-send-title {
        display: inline-block;
        font-size: 16px;
        font-family: NotoSansRegular;
        letter-spacing: -1px;
        margin: 10px 0px;
    }
    .trade-send-title-box{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
    }
    .trade-send-button {
        width: 80px;
    }

    .trade-send-confirm-modal {
        font-size: 18px;
        font-family: NotoSansRegular;
        letter-spacing: -1px;
    }
    .trade-send-confirm-title {
        font-size: 20px;
        font-family: NotoSansRegular;
        letter-spacing: -1px;
        margin: 20px 0px;
    }
    .trade-send-confirm-item {
        display: inline-block;
        line-height: 30px;
        font-size: 16px;
        
    }
    .mytran-header-back-wrapper {
        float: left;
        cursor: pointer;
        & > img {
        width: 12px;
        margin-top: 6px
        }
    }
}