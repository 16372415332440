@media screen and (min-width: 950px) {
//변수
$mainColor : #35b7ae;
$fontFamliy : NotoSansRegular;
$pinkColor : #e62167;

    .transactionContent {
        background-color: #fff;
        border-radius: 40px;
        box-shadow: 0px 15px 15px 0px #d1d1d1;
        width: 900px;
        padding: 58px;
    }

    .headerPart {
        width: 100%;
        height: 50px;
    }

    .tranListText {
        float: left;
        color: #888888;
        font-family: NotoSansRegular;
        font-weight: bold;
        font-size: 15px;
    }

    .underArrowTransaction {
        float: left;
        width: 12px;
        margin-left: 8px;
        margin-top: 9px;
    }

    .refreshBtn {
        width: 20px;
        height: 20px;
        float: right;
        background-image: url('../img/transaction/refreshBtn.png');
        background-repeat: no-repeat;
        background-size: 100%;
        margin-top: 5px;
        cursor: pointer;
    }

    .bodyPart {

    }

    .leftMenu {
        float: left;
        width: 137px;
        background-image: url('../img/transaction/selectSectionBg.png');
        background-repeat: no-repeat;
        background-size: 100%;
        text-align: center;
        padding: 20px;
    }

    .leftTabText {
        color: #888888;
        font-family: NotoSansRegular;
        font-size: 16px;
        text-align: left;
        margin-top: 20px;
    }

    .leftSelectBox {
        margin-top: -10px !important;
        width: 100px !important;
        height: 30px !important;
        font-family: NotoSansRegular;
        font-size: 13px !important;
        color: #888888 !important;
        word-break: break-all !important;
    }

    .leftSelectBox::placeholder  {
        color: #693cff !important;
    }

    .mainColorBtn {
        background-color: #35b7ae;
        color: #fff;
        width: 102px;
        margin-top: 40px;
        padding: 5px;
        display: inline-block;
        text-align: center;
        cursor: pointer;
    }
    .mainColorBtnLarge {
        background-color: #35b7ae;
        color: #fff;
        padding: 8px 30px;
        display: inline-block;
        text-align: center;
        cursor: pointer;
        font-family: NotoSansMedium;
        font-size: 16px;
    }
    .grayColorBtnLarge {
        background-color: #fff;
        color: #666666;
        border: 1px #e1e1e1 solid;
        padding: 8px 30px;
        display: inline-block;
        text-align: center;
        cursor: pointer;
        font-family: NotoSansMedium;
        font-size: 16px;
    }

    .rightContent {
        float: left;
        width: 80%;
        padding-left: 30px;
    }

    .btnTab {
        text-align: right;
    }

    .myHisBtn {
        float: right;
        margin-right: 25px;
        cursor: pointer;
    }

    .selRegBtn {
        float: right;
        margin-right: 0px;
        cursor: pointer;
    }
  

    // .innerLine {
    //     display: inline-block;
    //     width: 100%;
    //     height: 10px;
    //     background-image: url('../img/transaction/innerLine.png');
    //     background-repeat: no-repeat;
    //     background-size: 100%;
    // }

    .trContent {
        display: flex;
     align-items: center;
     justify-content: space-around;
     border-top: 1px solid #e2e2e2;
     padding: 12px 0;
     margin-top: 16px;
    }

    .selCoinText {
        color: #888888;
        font-family: NotoSansRegular;
        font-size: 16px;
    }

    .buyCoinText {
        color: #888888;
        font-family: NotoSansRegular;
        font-size: 16px;
    }

    .tcText {
 
        color: #888888;
        font-family: NotoSansRegular;
        font-size: 16px;

    }

    .transactionDetailBox {
        width: 100%;
        padding: 15px;
        border: #e2e2e2 1px solid;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .transcatioin-container{
        width: 75%;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    .sellCoinContainer{
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .coinImage {
        max-width: 45px;
        height: 45px;
    }
    .coinBox {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 12px;
        & > p {
            margin: 0;
            font-family: $fontFamliy;
        }
    }
    .arrowBox img{
        width: 20px;
    }

    .coinName {
        color: #888888;
        font-weight: bold;
        font-size: 16px;
    }

    .coinAmount {
        color: #693cff;
        font-weight: bold;
        font-size: 18px;
        font-family: $fontFamliy;
    }

    .coinSymbol {
        color: #888888;
        font-family: NotoSansRegular;
        margin-left: 3px;
    }

    .coinKo {
        color: #888888;
        font-family: NotoSansRegular;
        font-size: 12px !important;
    }
    .btn-container{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .doTransactionBox {
        width: 105px;
        padding: 5px 0px;
        background-color: $pinkColor;
        color: #fff;
        font-family: NotoSansRegular;
        font-size: 14px;
        cursor: pointer;
    }
    .doCancelTransactionBox {
        width: 105px;
        padding: 5px 0px;
        background-color: #777;
        color: #fff;
        font-family: NotoSansRegular;
        font-size: 14px;
        cursor: pointer;
    }

    .completeTransactionBox { 
        width: 105px;
        padding: 5px 0px;
        background-color: #e1e1e1;
        font-family: NotoSansRegular;
        font-size: 14px;
    }

    .cancelTransactionBox { 
        width: 105px;
        padding: 5px 0px;
        background-color: #e1e1e1;
        font-family: NotoSansRegular;
        font-size: 14px;
    }

    .registData {
        width:100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 20px;
        & > p{
            margin-top: 4px;
            margin-bottom: 0;
        }
    }

    .registUser { 
        font-family: NotoSansRegular;
        font-size: 12px !important;
        color: #888888;
        margin-left: 22px;
  
    }

    .registDate {     
        font-family: NotoSansRegular;
        font-size: 12px !important;
        color: #888888;

    }

    .selOption {
        word-break: break-all;
    }

    .selImg {
        max-width: 25px;
        max-height: 25px;
        margin-right: 5px;
    }

    .selName {
        font-family: NotoSansRegular;
        font-size: 13px !important;
        color: #888888 !important;
        word-break: break-all !important;
    }

    .selBox::placeholder{
        color: #693cff !important;
    }

    .leftSelectBox::placeholder{
        color: #693cff !important;
    }

    /// myTransaction
    .buyListBtn {
        float: right;
        width: 105px;
        height: 100px;
        background-image: url('../img/transaction/buyListBtn.png');
        background-repeat: no-repeat;
        background-size: 100%;
        cursor: pointer;
        margin-right: 25px;
    }

    .sellListBtn {
        float: right;
        width: 105px;
        height: 100px;
        background-image: url('../img/transaction/sellListBtn.png');
        background-repeat: no-repeat;
        background-size: 100%;
        cursor: pointer;
        margin-right: 25px;
    }

    // modal popup
    .modal {
        display: none;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 99;
        background-color: rgba(0, 0, 0, 0.2);
    }
    .modal button {
        outline: none;
        cursor: pointer;
        border: 0;
    }
    .modal > section {
        width: 80%;
        max-width: 700px;
        margin:0 auto;
        border-radius: .6rem;
        background-color: #fff;
        /* 팝업이 열릴때 스르륵 열리는 효과 */
        animation: modal-show .3s;
        overflow: hidden;
    }

    .modalHeader {
        width: 100%;
        text-align: right;
        padding: 15px 20px;
    }

    .exitBtn {
        width: 14px;
        height: 14px;
        background-image: url('../img/transaction/exitBtn.png');
        background-repeat: no-repeat;
        background-size: 100%;
        background-color: transparent;
    }
    .modal > section > main {
        padding: 0px 20px 20px 20px;
        text-align: center;
    }

    .openModal {
        display: flex;
        align-items: center;
        /* 팝업이 열릴때 스르륵 열리는 효과 */
        animation: modal-bg-show .3s;
    }
    @keyframes modal-show {
        from {
            opacity: 0;
            margin-top: -50px;
        }
        to {
            opacity: 1;
            margin-top: 0;
        }
    }
    @keyframes modal-bg-show {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    .doTransaction {
        padding: 20px 0px;
    }

    .exMark {
        margin-bottom: 15px;
    }

    .warnText {
        font-family: NotoSansRegular;
        font-size: 16px;
        color: #888888;
        text-align: center;
        word-break: keep-all;
    }

    .buyConfirmBtn {
        margin-top: 30px;
    }

    .cancelBtn {
        width: 100px;
        height: 33px;
        background-image: url('../img/transaction/cancelBtn.png');
        background-repeat: no-repeat;
        background-size: 100%;
        margin-right: 30px;
    }

    .confirmBtn {
        width: 100px;
        height: 33px;
        background-image: url('../img/transaction/confirmBtn.png');
        background-repeat: no-repeat;
        background-size: 100%;
    }

    .warringModal {
        padding: 80px 0px;
    }

    .errText {
        font-family: NotoSansRegular;
        font-size: 14px !important;
        color: #888888 !important;
    }

    .regCelCoinTextBox {
        width: 100%;
        display: inline-block;
        margin-top: 10px;
        margin-bottom: 4px;
        font-family: NotoSansRegular;
        font-size: 14px !important;
        color: #888888 !important;
    }

    .regCelCoinText {

    }

    .selCoinBox {
        padding: 0px 30px;
        text-align: left;
    }

    .warringBtn {
        margin-top: 113px;
    }

    .coinSelBox {
        width: 35% !important;
        float: left;
    }

    .selCoinAmount {
        width: 60% !important;
        float: right;
    }

    .getCoinAmount {
        width: 60% !important;
        float: right;
    }

    .coinRegRuleText {
        font-family: NotoSansRegular;
        font-size: 14px !important;
        color: #888888 !important;
        display: inline-block;
        margin-top: 20px;
    }

    .regSelBtnDiv {
        width: 140px;
        margin: 0 auto;
        text-align: center;
        margin-top: 20px;
        background-color: $pinkColor;
        color: #fff;
        line-height: 36px;
        cursor: pointer;
        font-weight: bold;
        text-align: center;
    }

    .regSelBtn {
        width: 100px;
        height: 33px;
        background-image: url('../img/transaction/regSelBtn.png');
        background-repeat: no-repeat;
        background-size: 100%;
    }

    .selRegDetail{
        padding: 0px 15px;
    }

    .selCoinTrans {
        width: 45%;
        display: inline-block;
        vertical-align: top;
        margin: 10px;
    }

    .getCoinTrans {
        width: 45%;
        display: inline-block;
        vertical-align: top;
        margin: 10px;
    }

    .selDiv {
        border: #e2e2e2 1px solid;
        padding: 10px;
        margin-top: 15px;
        border-radius: 10px;
    }

    .getDiv {
        border: #e2e2e2 1px solid;
        padding: 10px;
        margin-top: 15px;
        border-radius: 10px;
    }

    .coinTransText {
        font-family: NotoSansRegular;
        font-size: 16px !important;
        color: #888888 !important;
    }

    .showFeeAmount {
        display: inline-block;
        color: #ffffff;
        font-family: NotoSansRegular;
        font-size: 16px;
        padding: 10px 30px;
        border-radius: 10px;
        margin: 15px;
        background-color: #693cff;
    }

    .transDetailCost {
        font-family: NotoSansMedium;
        font-size: 20px !important;
        color: #693cff !important;
    }

    .transDetailName {
        font-family: NotoSansRegular;
        font-size: 16px !important;
        color: #888888 !important;
    }

    .transDetailSymbol {
        font-family: NotoSansRegular;
        font-size: 14px !important;
        color: #888888 !important;
    }

    .selRegConfirmBtn {
        margin-top: 20px;
    }
    .backBtn2 {
        width: 17px;
        height: 17px;
        background-image: url('../img/transaction/backBtn.png');
        background-repeat: no-repeat;
        background-size: 50%;
        background-color: transparent;
        float: left;
        margin-top: -36px;
    }

    .mytran-header-container {
        height: 100px;
    }

    .mytran-header-button-wrapper {
        float: right;
    }
    .mytran-list-container {
    }
    .mytran-list-item-container {
        padding: 20px;
        border: 1px #e1e1e1 solid;
        border-radius: 20px;
        margin: 0px 0px 20px 0px;
        text-align: left;
    }
    .mytran-list-item-detail {
        display: inline-block;
        text-align: left;
        margin: 0px 50px;
        font-family: NotoSansRegular;
        font-size: 16px;
    }
    .mytran-list-item-status {
        float: right;
        text-align: right;
    }
    .mytran-list-item-coin {
        margin: 8px 0px;
    }
    .mytran-list-item-coin-num1 {
        font-family: NotoSansMedium;
        color: #693cff;
        font-size: 18px;
    }
    .mytran-list-item-coin-num2 {
        font-family: NotoSansMedium;
        color: #ff7f50;
        font-size: 18px;
    }
    .mytran-list-item-date {
        margin: 8px 0px;
        font-family: NotoSansRegular;
        color: #999999;
        font-size: 14px;
    }
    .mytran-list-item-fee {
        margin: 8px 0px;
        font-family: NotoSansRegular;
        color: #999999;
        font-size: 14px;
        
    }
    .mytran-list-item-symbol {
        font-family: NotoSansRegular;
        color: #999999;
        font-size: 14px;
    }
    .mytran-list-item-status-text {
        font-family: NotoSansRegular;
        color: #999999;
        font-size: 16px;
    }
    .mytran-list-item-status-cancel {
        background-color: #777;
        font-family: NotoSansMedium;
        color: #fff;
        font-size: 16px;
        padding: 7px 20px;
        border-radius: 10px;
        margin-top: 10px;
        text-align: center;
        cursor: pointer;
    }

}