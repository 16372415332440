
@media screen and (min-width: 950px) {

//변수
$mainColor : #35b7ae;
$fontFamliy : NotoSansRegular;

.wrapper {
  background-color: #f5f5f5;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logForm {
    width: 900px; 
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    background-image: url('../img/loginPage/loginBox.png');
    background-size: contain;
    background-repeat: no-repeat;  
}

.logo { 
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  & img{
  width: 280px;
  height: auto;
  padding-bottom: 30px;
  }
}

.form {
  width: 60%;
  padding: 0 80px;
  display: flex;
  flex-direction: column;
  .title-wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: $fontFamliy;
    font-size: 18px;
  }
  .title-wrap2{
    margin-left: auto;
    & span {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: -1px;
      font-family: $fontFamliy;
      margin-bottom: 4px;
      margin-left: 16px;
    }

  .logTitle{
    color: $mainColor;
  }
  .loginTitle{
    color:#888;
    cursor: pointer;
    font-weight: 300;
  }
  
}
  .bar {
    width: 100%;
    height: 3px;
    background-color: $mainColor;
  }
  .idBox{
    width: 100%;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .logText{
    color: #888;
    font-size: 16px;
    margin: 0;
    font-family: $fontFamliy;
  }
  .inputId{
    margin-top: 10px;
  }
}

.autoLogin {
  float: right;
  font-family: NotoSansRegular;
  font-size: 16px;
  color: #999999;
}

.inputId {
  height: 45px;
}

.inputId::placeholder {
  font-family: NotoSansRegular!important;
}

.inputPw {
  height: 45px;
}

.inputPw::placeholder {
  font-family: NotoSansRegular!important;
}

.pwSection {
  width: 300px;
  height: 30px;
  display: inline-block;
}

.pwBox {
  width: 300px;
  display: inline-block;
}

.signInBtn {
  cursor: pointer;
  border: 1px solid #b7b7b7;
  padding: 4px 24px;
  display: inline-block;
  color: #b7b7b7;
  font-family: NotoSansMedium;
  font-size: 18px;
  border-radius: 10px;
}
.box-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logBtn {
  width: 60%;
  cursor: pointer;
  background-color:$mainColor;
  padding: 10px 30px;
  color: #fff;
  font-family: NotoSansMedium;
  font-size: 16px;
  border: none;
  margin-top: 16px;
}

.findIdPwBtn{
  width: 100%;
  cursor: pointer;
  text-align: center;
  color: #888;
  font-family: $fontFamliy;
  font-size: 16px;
  margin-top: 24px;
}

.findIdPwBtn:hover{
  color:#888;
}

.findBotton {
  height: 40px;
  background-image: url('../img/loginPage/findIdPw.png');
  background-repeat: no-repeat;
  background-size: 65px 13px;
  background-position-x: right;
  background-position-y: center;
  margin-right: 115px;
  margin-top: 10px;
}

/// signup1

.numSection{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  margin: 12px 0;
}
.activeNum{
  font-weight: bold;
  color: $mainColor;
  border: 2px solid $mainColor;
  padding: 0px 9px;
  border-radius: 50%;
}
.normalNum{
  color: #888;
  padding: 0px 9px;
}

.noti {
  margin-top: 50px;
  height: 33px;
}
.notiText{
  font-family: $fontFamliy;
  color:  #888;
  font-size: 14px;
  word-break: keep-all;
}
.signUpContent{
  margin-top: 16px;
  height: 340px;
  padding-bottom: 16px;
}

.signUpContent.finish{
  margin-top: 16px;
  height: 340px;
  padding: 32px 0;
}

.termSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: $fontFamliy;

}
.checkTerm{
  display: flex;
  align-items: center;
}
.termCheck{
  width: 13px;
  height: 13px;
  margin-right: 6px;
}

.term {
  width: 100%;
  height: 200px;
  overflow-y: scroll;
  font-size: 12px;
  background-color: #fff;
  padding: 12px;
  margin-top: 12px;
}
.agreement-content{
  width: 850px;
  height: 500px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.agreement-content-title {
  color: #555;
  text-decoration: underline;
  font-weight: bold;
  padding: 4px 0px;
}

.agreement-content-body {
  font-size: 12px;
  color: #888;
}
.nextBtn-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nextBtn.ant-btn{
  background-color: inherit;
  box-shadow: none;
  border: none;
  margin-top: 16px;
  &:hover, &:focus, &:active{
    background-color: inherit;
    color: #222;
    font-weight: bold;
  } 
  &:disabled {   
    color: rgba(0, 0, 0, 0);
    &:hover, &:focus, &:active{
      color: rgba(0, 0, 0, 0);
    }
  }
}
.signUp-input{
  height: 36px;
}
.formItem-box{
  font-size: 16px;
  color:#888;
  font-family: $fontFamliy;
  & > p { 
    margin-top: 0 ;
    margin-bottom: 6px;
  }
}
.formItem-box.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.duplchecktext{
  font-size: 12px;
  font-family: $fontFamliy;
  margin-left: 6px;
}
.input-flex{
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
  .ant-form-item {
    width:100%;
    margin: 0
  }
  &>button {
    height: 36px;
  }
}
.pw-sub-text{
  font-family: $fontFamliy;
  font-size: 12px;
  color:#b5b5b5;
  margin-left: 6px;
}
.finishTitle{
  font-family: $fontFamliy;
  color:$mainColor;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
}
.finishText1{
  font-family: $fontFamliy;
  text-align: center;
  font-size: 18px;
  color: #222;
  word-break: keep-all;
}
.finishBtn{
  background-color: #fff;
  color:#222;
  font-size: 16px;
  font-family: $fontFamliy;
  text-align: center;
  width: 100%;
  line-height: 40px;

}

.backBtn {
  float: left;
  background-image: url('../img/signUpCommon/backBtn.png');
  width: 80px;
  height: 25px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: 0px;
  background-position-x: right;
  background-position-y: center;
  margin-top: 26px;
  margin-left: 150px;
}

.login-field-icon {
  text-align: right;
  color: #693cff;
  font-family: NotoSansMedium;
  font-size: 24px;
}

.findTypeBtn-container {
  display: flex;
  align-items: center;
  margin: 16px 0;
  & > button {
    width: 100%;
    height: 36px;
    font-family: $fontFamliy;
    &:active, &:hover, &:focus{
      background-color: $mainColor;
      color:#fff;
    }
  }
  .activeType {
    background-color: $mainColor;
    color:#fff;
  }
}

.findBtn {
  width: 60%;
  cursor: pointer;
  background-color:$mainColor;
  padding: 10px 30px;
  color: #fff;
  font-family: NotoSansMedium;
  font-size: 16px;
  border: none;
  margin-top: -140px;
}
.findIdPw-sub{
  font-family: $fontFamliy;
  color:#888;
  word-break: keep-all;
}

}